import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { DateTime } from 'luxon'
import { selectAcceptedTermsAndConditions, selectUserInfo, setUserInfo } from '../../redux/slices/main'

const currentDate = DateTime.now()

function GetQuote () {
  const acceptedTermsAndConditions = useSelector(selectAcceptedTermsAndConditions)
  const userInfo = useSelector(selectUserInfo)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleSubmit = (event) => {
    event.preventDefault()
    const form = event.target
    console.log('submitting...', form)
    if (!form.checkValidity()) {
      event.stopPropagation()
    }

    form.classList.add('was-validated')
  }

  const handleChange = (event) => {
    const target = event.target
    dispatch(setUserInfo({ userInfo: Object.assign({}, userInfo, { [target.name]: target.value }) }))
  }

  useEffect(() => {
    if (!acceptedTermsAndConditions) {
      navigate('/')
    }
  }, [acceptedTermsAndConditions, navigate])

  return (
    <div className="text-center">
      <h1>Variable Title</h1>

      <hr className='bg-primary' />

      <form className='needs-validation' noValidate onSubmit={handleSubmit}>
        <div className="mb-2 row">
          <label htmlFor="firstName" className="col-5 col-form-label text-start">First Name <span className='text-orange'>*</span></label>
          <div className="col-7">
            <input type="text" className="form-control" id="firstName" name="firstName" value={userInfo.firstName} onChange={handleChange} placeholder='first name' required />
          </div>
        </div>

        <div className="mb-2 row">
          <label htmlFor="lastName" className="col-5 col-form-label text-start">Last Name <span className='text-orange'>*</span></label>
          <div className="col-7">
            <input type="text" className="form-control" id="lastName" name="lastName" value={userInfo.lastName} onChange={handleChange} placeholder='last name' required />
          </div>
        </div>

        <div className="mb-2 row">
          <label htmlFor="birthdate" className="col-5 col-form-label text-start">Date of Birth <span className='text-orange'>*</span></label>
          <div className="col-7">
            <input type="date" className="form-control" id="birthdate" name="birthdate" value={userInfo.birthdate} onChange={handleChange} max={currentDate.toISODate()} required />
          </div>
        </div>

        <div className="mb-2 row">
          <label htmlFor="email" className="col-5 col-form-label text-start">Email <span className='text-orange'>*</span></label>
          <div className="col-7">
            <input type="email" className="form-control" id="email" name="email" value={userInfo.email} onChange={handleChange} required />
          </div>
        </div>

        <div className="mb-2 row">
          <label htmlFor="addressOne" className="col-5 col-form-label text-start">Address 1 <span className='text-orange'>*</span></label>
          <div className="col-7">
            <input type="text" className="form-control" id="addressOne" name="addressOne" value={userInfo.addressOne} onChange={handleChange} required />
          </div>
        </div>

        <div className="mb-2 row">
          <label htmlFor="addressTwo" className="col-5 col-form-label text-start">Address 2</label>
          <div className="col-7">
            <input type="text" className="form-control" id="addressTwo" name="addressTwo" value={userInfo.addressTwo} onChange={handleChange} />
          </div>
        </div>

        <div className="mb-2 row">
          <label htmlFor="city" className="col-5 col-form-label text-start">City <span className='text-orange'>*</span></label>
          <div className="col-7">
            <input type="text" className="form-control" id="city" name="city" value={userInfo.city} onChange={handleChange} required />
          </div>
        </div>

        <div className="mb-2 row">
          <label htmlFor="state" className="col-5 col-form-label text-start">State <span className='text-orange'>*</span></label>
          <div className="col-7">
            <input type="text" className="form-control" id="state" name="state" value={userInfo.state} onChange={handleChange} required />
          </div>
        </div>

        <div className="mb-5 row">
          <label htmlFor="zipcode" className="col-5 col-form-label text-start">Zip Code <span className='text-orange'>*</span></label>
          <div className="col-7">
            <input type="text" className="form-control" id="zipcode" name="zipcode" value={userInfo.zipcode} onChange={handleChange} required />
          </div>
        </div>

        <button type='submit' className='btn btn-primary float-end'>Get Quote</button>
      </form>
    </div>
  )
}

export default GetQuote
