import { createSlice } from '@reduxjs/toolkit'
import { setSessionStorageValue } from '../../utils/store'

const initialState = {
  acceptedTermsAndConditions: false,
  userInfo: {
    firstName: '',
    lastName: '',
    birthdate: '',
    email: '',
    phone: '',
    addressOne: '',
    addressTwo: '',
    city: '',
    state: '',
    zipcode: ''
  },
  status: 'idle'
}

export const mainSlice = createSlice({
  name: 'main',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setUserInfo: (state, action) => {
      setSessionStorageValue('pUserInfo', JSON.stringify(action.payload.userInfo))
      state.userInfo = action.payload.userInfo
    },
    setAcceptedTermsAndConditions: (state, action) => {
      state.acceptedTermsAndConditions = action.payload.acceptedTermsAndConditions
    }
  }
})

export const { setUserInfo, setAcceptedTermsAndConditions } = mainSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUserInfo = (state) => state.main.userInfo
export const selectAcceptedTermsAndConditions = (state) => state.main.acceptedTermsAndConditions

export default mainSlice.reducer
