import { Link } from 'react-router-dom'

function NotFound () {
  return (
    <div className="text-center">
      <h1>Content Not Found</h1>

      <Link className='btn btn-primary w-100' to='/'>Go Back</Link>
    </div>
  )
}

export default NotFound
