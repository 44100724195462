import { configureStore } from '@reduxjs/toolkit'
import mainReducer, { setUserInfo } from './slices/main'
import { getSessionStorageValue } from '../utils/store'

const store = configureStore({
  reducer: {
    main: mainReducer
  }
})

const userInfo = getSessionStorageValue('pUserInfo')
if (userInfo) {
  store.dispatch(setUserInfo({ userInfo: JSON.parse(userInfo) }))
}

export default store
