function HomeIcon ({ width = 66, height = 66, color = '#2676E6', className }) {
  return (
    <svg width={width} height={height} viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className={className}>
      <mask id="mask0_813_317" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width={width} height={height}>
        <rect width={width} height={height} fill="url(#pattern0)"/>
      </mask>
      <g mask="url(#mask0_813_317)">
        <rect x="-10" y="-7" width="86" height="80" fill={color} />
      </g>
      <defs>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image0_813_317" transform="scale(0.01)"/>
        </pattern>
        <image id="image0_813_317" width="100" height="100" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAABmJLR0QA/wD/AP+gvaeTAAADj0lEQVR4nO2cXYgNYRyHn2VjfcuGyJaQEheIktImZa2vlFLKbintrUt3cqlcuSPlglJKUYqkKCkfJcmF9sLHfhBCPnex7LqYMzk7O2fPnDNz5v3PnN9Tv5vtnLP/9/fsaefMzHtACJFv2oCHhbQ5nqWuaQCOAH+BkUKGgePABIdz1SUzgcv8FxHMNWCOs+nqjNXAc0rL8NMDrHc0Y91wAPhBeRl+BoFDTibNOZOBk0QXEcw5YErqU+eURcA9qpfh5xGwOOXZc0cr8Jb4Mvx8ALamuoKc0AAcBoZIToafP8Axyh8aV/q6uWUGcInkRQRzFZg9zhwSAqwCuqm9DD/dhd8ZxlFGf+isOyH7ge+kJ8PPIHCwxEybgXcRXyc3NOKd7khbRDCngUkh87UA9yM8PxfMA27hXoafu8DCkDmjfA7KPJuAN7iXEMx7YEuJmTspfaYg03QBv3FffqkM4Z1JbgiZfQ3wIuQ5mWQ6cBH3hUfNFWBWyDqageuBx2aO5cBT3JdcabqBlSHrCV6PyRS7gc+4L7fafAP2lVjbduBTjG5SpRE4gXclz3WpcTNcWEtjyDqXxikpLeYCN3FfZNK5AyxIsKdUWAe8wn15tcprYGNibdWYLuAX7kurdfxDY7M0AWdxX1TauQBMS6C/RFkGPMF9Oa7yDFgRu8WE2Il32Oe6FNf5CuyN2WUswm5Uq/f4N+pNjNFrVTQDN2IMnvfcBuZX3W6FrCX85JoyOn3Ahio7jkwnMOB4oVnKT7ybNRKnCThjYIFZzXlgasWtl6AFeGBgUVnPY2BJhd2PoR34aGAxeckXYE9FBgrokLZ2qXgPS7m9F0oyibSHJereCyWZjLuHZRc6pHWRgUL3Y+gPeXCUG8SUyhLWaZ8vofgfy0hA0Cm87QAiWVrxui1mOOyBO/DeJS+BjqKfu/6Lylt8OvCuPPYA2/wfht0IFiT4zhHxGLdz7ec2hoQYQ0KMISHGkBBjSIgxJMQYEmIMCTGGhBhDQowhIcaQEGNIiDEkxBhhGxitEOVaTRxMXufRO8QYEmIMCTGGhBhDQowhIcaQEGNIiDEkxBgSYgwJMYaEGENCjCEhxpAQY0iIMSTEGBJiDAkxhoQYQ0KMISHGkBBjSIgxJMQYEmIMCTGGhBhDQowhIcaQEGNIiDEkxBhRhPTXfIr6oa/8Q8rTDvTi7rsJa0Xa6+ml6LsVhRBCCCFEmvwDye5H34spSTsAAAAASUVORK5CYII="/>
      </defs>
    </svg>
  )
}

export default HomeIcon
