import {
  BrowserRouter as Router,
  Routes as Switch,
  Route
} from 'react-router-dom'
import { routes } from './routes'
import NotFound from './views/NotFound'

function App () {
  return (
    <div className='container'>
      <Router>
        <Switch>
          <Route path='*' element={<NotFound />} />

          {routes.map(({ path, exact, Component }) => (
            <Route key={path} path={path} element={<Component />} />
          ))}
        </Switch>
      </Router>
    </div>
  )
}

export default App
