import Welcome from './views/Welcome'
import Authorization from './views/Authorization'
import GetQuote from './views/GetQuote'

const routes = [
  { path: '/', Component: Welcome, exact: true },
  { path: '/authorization', Component: Authorization, exact: true },
  { path: '/get-quote', Component: GetQuote, exact: true }
]

export { routes }
