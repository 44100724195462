import HomeIcon from '../../assets/styles/images/icons/HomeIcon'
import ProgressiveSponsor from '../../components/ProgressiveSponsor'
import { Link, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { selectUserInfo, setUserInfo } from '../../redux/slices/main'

function Welcome () {
  const [searchParams] = useSearchParams()
  const currentUserInfo = useSelector(selectUserInfo)
  const dispatch = useDispatch()

  useEffect(() => {
    const newUserInfo = Object.assign({}, currentUserInfo)
    searchParams.forEach((value, key) => {
      if (key in newUserInfo) {
        newUserInfo[key] = newUserInfo[key] || value
      }
    })

    dispatch(setUserInfo({ userInfo: newUserInfo }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="text-center">
      <h1 className='text-center'>Find homeowner's insurance that fits your needs.</h1>

      <HomeIcon className='my-3' />

      <p>Make sure you have proof of insurance for your home. Just by clicking, Progressive Home® can provide you home insurance quotes as well as options to buy. We will quote multiple carriers for you, with no requirement to buy.</p>

      <Link to='/authorization' className='btn btn-primary w-100 mb-4'>Continue</Link>

      <ProgressiveSponsor />
    </div>
  )
}

export default Welcome
