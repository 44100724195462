import progressiveHome from '../../assets/styles/images/progressive-home.svg'

function ProgressiveSponsor () {
  return (
    <div className='mx-auto mt-2' style={{ width: '250px' }}>
      <div className='text-start fs-14 fw-medium mb-2'>Sponsored By:</div>
      <img src={progressiveHome} alt='' className='mx-auto' />
    </div>
  )
}

export default ProgressiveSponsor
