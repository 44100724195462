import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import HomeIcon from '../../assets/styles/images/icons/HomeIcon'
import ProgressiveSponsor from '../../components/ProgressiveSponsor'
import { selectAcceptedTermsAndConditions, setAcceptedTermsAndConditions } from '../../redux/slices/main'

function Authorization () {
  const acceptedTermsAndConditions = useSelector(selectAcceptedTermsAndConditions)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleSubmit = (event) => {
    event.preventDefault()
    if (acceptedTermsAndConditions) {
      navigate('/get-quote')
    }
  }

  const handleAccept = (event) => {
    dispatch(setAcceptedTermsAndConditions({ acceptedTermsAndConditions: event.target.checked }))
  }

  return (
    <div className="text-center">
      <h1>Insurance Authorization</h1>

      <HomeIcon className='my-3' />

      <p>By Clicking Shop Now! You agree to share your personal information, such as contact information and home type with Progressive Advantage Agency to obtain a homeowner’s insurance policy quote. Progressive Home, through Progressive Advantage Agency, provides homeowners insurance policies through affiliated and third-party insurers and may receive a commission if you purchase a policy. Refer to Progressive’s Privacy Policy for more information.</p>

      <form className='needs-validation' noValidate onSubmit={handleSubmit}>
        <div className='form-check mb-3 mx-auto' style={{ maxWidth: '290px' }}>
          <input type='checkbox' className='form-check-input' id='accept-progressive-tyc' onChange={handleAccept} checked={acceptedTermsAndConditions} required />
          <label className='form-check-label fs-12 text-start ms-1' htmlFor='accept-progressive-tyc'>I agree to share my personal information with Progressive to obtain a homeowner’s insurance quote.</label>
        </div>

        <button type='submit' className='btn btn-primary w-100 mb-4' disabled={!acceptedTermsAndConditions}>Shop Now!</button>
      </form>

      <ProgressiveSponsor />
    </div>
  )
}

export default Authorization
